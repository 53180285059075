import server from '../request';

// 统计消息提醒数量
export const queryCount = (data) => {
  return server.post('/xiaozi-xmb/wireless/saas/messageRemind/count', data);
};

// 消息提醒详情
export const queryDetail = (data) => {
  return server.post('/xiaozi-xmb/wireless/saas/messageRemind/queryDetail', data);
};

// 消息提醒列表
export const queryPage = (data) => {
  return server.post('/xiaozi-xmb/wireless/saas/messageRemind/queryPage', data);
};

// 更新阅读标识
export const updateReadFlag = (data) => {
  return server.post('/xiaozi-xmb/wireless/saas/messageRemind/updateReadFlag', data);
};
